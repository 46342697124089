import { blackFrame } from "./black";
import { boardFrame } from "./board";
import { buttonFrame } from "./button";
import { cafeFrame } from "./cafe";
import { envelopeFrame } from "./envelope";
import { mobileFrame } from "./mobile";
import { museumFrame } from "./museum";
import { receiptFrame } from "./receipt";
import { shoppingFrame } from "./shopping";
import { ticketFrame } from "./ticket";
import { whiteFrame } from "./white";
import { eyelashesFrame } from "./eyelashes";
import { hairsalonFrame } from "./hairsalon";
import { beautyFrame } from "./beauty";
import { careFrame } from "./care";
import { waiterFrame } from "./waiter";
import { bucketFrame } from "./bucket";
import { takeawayFrame } from "./takeaway";
import { cupFrame } from "./cup";
import { brochureFrame } from "./brochure";
import { asianFrame } from "./asian";
import { sodaFrame } from "./soda";
import { menuFrame } from "./menu";
import { restaurantFrame } from "./restaurant";
import { wineFrame } from "./wine";
import { headphonesFrame } from "./headphones";
import { micFrame } from "./mic";
import { concertFrame } from "./concert";
import { videogameFrame } from "./videogame";
import { movieFrame } from "./movie";
import { musicFrame } from "./music";
import { mp3Frame } from "./mp3";
import { filmFrame } from "./film";
import { handFrame } from "./hand";
import { likeFrame } from "./like";
import { chatFrame } from "./chat";
import { starsFrame } from "./stars";
import { reviewFrame } from "./review";
import { ticket2Frame } from "./ticket2";
import { weddingFrame } from "./wedding";
import { christmasFrame } from "./christmas";
import { partyFrame } from "./party";
import { ticketsFrame } from "./tickets";
import { celebrationFrame } from "./celebration";
import { partyhatFrame } from "./partyhat";
import { balloonsFrame } from "./balloons";
import { garlandFrame } from "./garland";
import { bouquetFrame } from "./bouquet";
import { giftboxFrame } from "./giftbox";
import { cardsFrame } from "./cards";
import { shoppingbagFrame } from "./shoppingbag";
import { cartFrame } from "./cart";

export {
  blackFrame,
  boardFrame,
  buttonFrame,
  cafeFrame,
  mobileFrame,
  museumFrame,
  receiptFrame,
  shoppingFrame,
  ticketFrame,
  whiteFrame,
  envelopeFrame,
  eyelashesFrame,
  hairsalonFrame,
  beautyFrame,
  careFrame,
  waiterFrame,
  bucketFrame,
  takeawayFrame,
  cupFrame,
  brochureFrame,
  asianFrame,
  sodaFrame,
  menuFrame,
  restaurantFrame,
  wineFrame,
  headphonesFrame,
  micFrame,
  concertFrame,
  videogameFrame,
  movieFrame,
  musicFrame,
  mp3Frame,
  filmFrame,
  handFrame,
  likeFrame,
  chatFrame,
  starsFrame,
  reviewFrame,
  ticket2Frame,
  weddingFrame,
  christmasFrame,
  partyFrame,
  ticketsFrame,
  celebrationFrame,
  partyhatFrame,
  balloonsFrame,
  garlandFrame,
  bouquetFrame,
  giftboxFrame,
  cardsFrame,
  shoppingbagFrame,
  cartFrame,
};
